import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {QRCodeCanvas} from 'qrcode.react';
import { Pagination } from 'swiper/modules';
import './App.css';
import 'swiper/css';
import 'swiper/css/pagination';
import Page1 from './pages/page1';
import Page2 from './pages/page2';


function App() {
  const compartilhar = async () => {
    try {
      await navigator.share({
        title: 'Título do Compartilhamento',
        text: 'Texto do Compartilhamento',
        url: 'https://wa.me/5584996292759',
      })
      // console.log('Conteúdo compartilhado com sucesso!');
    } catch (error) {
      window.open('https://wa.me/5584996292759', '_blank')
    }
  };

  return (
    <div className="App">
      <Swiper
        grabCursor={true}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        modules={[Pagination]}
        className="mySwiper"

        pagination={{
          dynamicBullets: true,
        }}
      >
        <SwiperSlide className='slider1'>
          <Page1/>
        </SwiperSlide>

        <SwiperSlide className='slider1'>
          <Page2/>
        </SwiperSlide>

        <SwiperSlide className='slider12 compartilhar'>
          <div className='box-QRCode'>
            <QRCodeCanvas className='QRCode' value="https://lcc.na.publypage.com/" />

            <div className='qrCode-botao-compartilhar'>
              <button onClick={compartilhar} title='Compartilhar o catão digital'>Compartilhar</button>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default App;
