import './page1.css'
import logoBarraLateral from '../images/logo-LCC-2.png'
import logo from '../images/logo-LCC-3.png'
import Icones from '../components/icones'
import iconeWhatsapp from '../images/icone-whatsapp.png'
import iconeEmail from '../images/icone-email.png'
import mao from '../images/mãozinha2.png'
import seta from '../images/seta.png'
import check from '../images/check.png'
import InstallPWA from '../components/installPwa'


const iconesPage1 = {
  icones: [iconeWhatsapp, iconeEmail],
  iconesDescricao: ['Whatsapp LCC', 'Email'],
  iconesLinks: ['https://wa.me/5584996292759', 'mailto:Luizcarlosconsultor@hotmail.com']
}

const Page1 = () => {
  return (
    <div className="page1">
      <div className='barra-lateral'>
        <img className='logo' src={logoBarraLateral} alt="Logo da LCC Consultoria"/>
        
        {/* <button title='Botão de salvar o cartão' className='salvar'>Salvar</button> */}
        <InstallPWA/>
      </div>

      <div className='container'>
        <div className='container-logo'>
          <img src={logo} alt="LCC Consultoria" />
        </div>

        <div className='space'></div>

        <div className='container-topicos'>
          <ul className='lista-check'>
            {
              [1,2,3,4,5,6,7].map(element => {
                return <li><img src={check} alt="" /></li>
              })
            }
          </ul>

          <ul className='lista'>
            <li>Linhas de créditos</li>
            <li>Reestabelecimento de crédito no mercado</li>
            <li>Renegociação de dívidas bancárias</li>
            <li>Renegociação de impostos</li>
            <li>Regularização de renajud veicular</li>
            <li>Palestras motivacionais/empresarial</li>
            <li>E outras demandas</li>
          </ul>
        </div>

        <div className='container-descricao2'>
          <p>
            Temos uma proposta exclusiva para impulsionar o seu desenvolvimento interno enquanto impacta positivamente o mundo ao seu redor
          </p>
        </div>

        <div className='seta'>
          <img src={seta} alt="seta para direita" />
        </div>

        <div className='container-icones'>
          <Icones icones={iconesPage1.icones} links={iconesPage1.iconesLinks} iconesDescricao={iconesPage1.iconesDescricao} />
        </div>

        <div className='click-icones'>
          <img src={mao} alt="Mão realizando um click"/>
          <span>Clique nos ícones</span>
        </div>
      </div>
    </div>
  )
}

export default Page1