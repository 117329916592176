import './page1.css'
import logo from '../images/logo-LCC.png'
import Icones from '../components/icones'
import iconeEmail from '../images/icone-email.png'
import iconeWhatsapp from '../images/icone-whatsapp.png'


const iconesPage2 = {
  icones: [iconeWhatsapp, iconeEmail],
  iconesDescricao: ['Contato LCC', 'Instagram da ONG'],
  iconesLinks: ['https://wa.me/5584996292759', 'https://www.instagram.com/assviuvas.rn?igshid=OGQ5ZDc2ODk2ZA==']
}

const Page2 = () => {
  return (
    <div className="page2">
      <div className='container-logo'>
        <img src={logo} alt="" />
      </div>

      <div className='container-descricao'>
        <div className='descricao'>
          <p>
            O diferencial desta proposta está no nosso compromisso em apoiar uma ONG de grande relevância. 
            Ao optar por nossos serviços, você tem a liberdade de determinar o valor que considera justo, com a 
            garantia de que a integralidade desse montante será destinada a essa causa nobre.
          </p>
        </div>

        <div className='descricao2'>
          <p>
            Contate-nos para discutir como podemos personalizar nossas palestras para atender às suas necessidades e fazer a diferença que realmente importa.
          </p>
        </div>
      </div>
      
      <div className='container-icones'>
        <Icones icones={iconesPage2.icones} links={iconesPage2.iconesLinks} iconesDescricao={iconesPage2.iconesDescricao} />
      </div>

      <div className='space'></div>
    </div>
  )
}

export default Page2