import { useEffect, useState } from "react";


const compartilhar = async () => {
  try {
    await navigator.share({
      title: 'Título do Compartilhamento',
      text: 'Texto do Compartilhamento',
      url: 'https://wa.me/5584996292759',
    })
    // console.log('Conteúdo compartilhado com sucesso!');
  } catch (error) {
    window.open('https://wa.me/5584996292759', '_blank')
  }
};

const InstallPWA = ()=>{
  const [deferredPrompt, setDeferredPrompt] = useState<any>()
  
  const installButton = async ()=>{
    try {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      
      if (outcome === 'accepted') {
        setDeferredPrompt(null)
      }
    } catch (e) {
      compartilhar()
      console.log('navegador não suportado');
    }
  }
  
  const install = ()=>{
    window.addEventListener('beforeinstallprompt', (e) => {
      setDeferredPrompt(e)
      
      if (e) {
        const button = document.getElementById('installPWAButton')
        if (button) {
          button.style.display = "block"
        }
      }
    });
  }

  useEffect(()=>{
    install()
  }, [])

  return (
    <button style={{display: 'none'}} id="installPWAButton" onClick={installButton}>Salvar</button>
  )
}

export default InstallPWA