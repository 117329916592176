import React from "react"
import './icones.css'


interface Props {
  icones: any[],
  iconesDescricao: string[],
  links: string[]
}

const Icones: React.FC<Props> = ({icones, iconesDescricao, links}) => {
  return (
    <div className="icones">
      { icones.map<any>((e, i) => (
        <a key={i} href={links[i]} target="_blank" rel="noreferrer">
          <img src={e} alt={iconesDescricao[i] ? iconesDescricao[i] : ''} />
          <span>{iconesDescricao[i]}</span>
        </a>
      ))}
    </div>
  )
}

export default Icones